<template>
  <div id="page-track" class="row main-content--finish-checkout xs-fill-white pt-15 lg:px-60" style="margin: auto;">
    <div class="selection-time text-center pb20 display-flex " style="position: relative;overflow: hidden;">
      <transition name="msg" mode="out-in">
        <div :key="command" class="flex-1 slideInRight animated tracker--current-step" style="width: 100%;">
          <div class="pt10 pr15 pl15 title-order ">
            <span>{{$t('tracker_heading')}}<ellipsis></ellipsis></span>
          </div>
          <div data-synth="order-status-wrapper" :class="orderStatus ===$const.ORDER_STATUS.COMPLETED ? 'text-green t16 bold pt10 title-order' : 'text-red t16 bold pt10 title-order'">
            <div data-synth="order-status">{{oStatus}}</div>
            <!-- {{$t('tracker_current_status', {status: oStatus})}}</span> <i class="fa fa-refresh fa-spin text-grey-light"></i></div> -->
          </div>
          <div class="text-black t16 pt10"
               v-if="orderStatus === $const.ORDER_STATUS.ERROR">
            {{ errorMessage }}
          </div>
          <div v-else>
            {{
            $t('order_estimated_time', {order_type : (cart.order_type &&
            cart.order_type.toLowerCase() ==='d') ? $t('tracker_delivery'): $t('tracker_collection'),
            date: cart.collection_time_str})
            }}
          </div>
        </div>
      </transition>
    </div>
    <div v-show="0">
      <div class="tracking-contain"
           v-if="orderStatus != $const.ORDER_STATUS.ERROR">
        <div class="display-flex flex-center">
          <div class="item"
               :class="{active : orderStatus ===$const.ORDER_STATUS.STARTING}">
            <img class="img-gif" :src="$const.ORDER_STATUS_IMAGE.STARTING.GIF"/>
            <img class="img-png" :src="$const.ORDER_STATUS_IMAGE.STARTING.PNG"/>
          </div>
          <div class="item"
               :class="{active : orderStatus ===$const.ORDER_STATUS.RECEIVED}">
            <img class="img-gif" :src="$const.ORDER_STATUS_IMAGE.RECEIVED.GIF"/>
            <img class="img-png" :src="$const.ORDER_STATUS_IMAGE.RECEIVED.PNG"/>
          </div>
          <div class="item"
               :class="{active : orderStatus ===$const.ORDER_STATUS.COOKING}">
            <img class="img-gif" :src="$const.ORDER_STATUS_IMAGE.COOKING.GIF"/>
            <img class="img-png" :src="$const.ORDER_STATUS_IMAGE.COOKING.PNG"/>
          </div>

          <div v-if="isDelivery"
               :class="{active : orderStatus ===$const.ORDER_STATUS.COMPLETED || orderStatus == $const.ORDER_STATUS.DELIVERING}"
               class="item">
            <img v-if="orderStatus ===$const.ORDER_STATUS.COMPLETED"
                 class="img-gif show"
                 :src="$const.ORDER_STATUS_IMAGE.DELIVERING.PNG"/>
            <img v-else-if="orderStatus ===$const.ORDER_STATUS.DELIVERING"
                 class="img-png show"
                 :src="$const.ORDER_STATUS_IMAGE.DELIVERING.GIF"/>
            <img v-else
                 class="img-png"
                 :src="$const.ORDER_STATUS_IMAGE.DELIVERING.PNG"/>
          </div>
          <div v-else
               :class="{active : orderStatus ===$const.ORDER_STATUS.COMPLETED || orderStatus == $const.ORDER_STATUS.READY}"
               class="item">
            <img v-if="orderStatus ===$const.ORDER_STATUS.COMPLETED"
                 class="img-gif show"
                 :src="$const.ORDER_STATUS_IMAGE.COMPLETED.PNG"/>
            <img v-else-if="orderStatus ===$const.ORDER_STATUS.READY"
                 class="img-png show"
                 :src="$const.ORDER_STATUS_IMAGE.COMPLETED.GIF"/>
            <img v-else
                 class="img-png"
                 :src="$const.ORDER_STATUS_IMAGE.COMPLETED.PNG"/>
          </div>
        </div>
      </div>

    </div>
    <div class="selection-cart text-center md:flex pb20">
      <div class="md:flex-1">
        <div class="bg-grey-lightestest rounded mr20 ml20">
          <header>
            <header class="flex justify-start items-center py-10 mx-10 md:mx-20 bold typo-l6 border-b">
              <div class="icon-basket--grey mr-10"></div>
              <div class="text-grey-darkest"><span>{{$t('order')}}</span>
                #<span>{{cart.order_number}}</span>
              </div>
            </header>
            <button
              class="text-grey-darkest flex justify-between items-center hidden-sm hidden-md hidden-lg p-10 w-full"
              v-on:click="show = !show" data-target="#cart-item-contain">
              <div class="flex items-center"><span>{{$t('show_summary')}}</span></div>
              <div class="flex items-center">
                <div class="bold mr-5 pl10">
                  <span class="summary-total-price">
                  {{
                    $t('currency_display', {
                        currency: $const.CURRENCY,
                        amount: formatNumDisplay(cart.total)
                    })
                  }}
                  </span>
                </div>
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
              </div>
            </button>
            <transition name="fade">
              <div v-if="show">
                <div class="cart-item-contain text-left pl10 pr10 collapse in" style="" id="cart-item-contain">
                  <div class="cart-item">
                    <div class="flex1" v-for="(item, i) in cart.order_items" :key="genUniqueKey(item)"
                         v-if="item.type ===0">
                      <div class="pt10 fw600">
                        <span class="block-left text-black t14">
                          <span>
                            <!--(item.items.length > 0 && Object.keys(item.items[0].first).length > 0 ? item.items[0].first.name : '')+' '+(item.name)-->
                            <span v-if="item.quantity > 1">{{$t('format_product',{
                                product_name: formatName(item),
                                quantity: item.quantity
                              })}}
                            </span>
                            <span v-else>{{(item.items.length > 0 && Object.keys(item.items[0].first).length > 0 ? item.items[0].first.name : '')+' '+(item.name)}}</span>
                          </span>
                        </span>
                        <div class="fw600 block-right  text-black  t14" data-synth="price-number">
                          <span>
                            {{
                              $t('currency_display', {
                                  currency: $const.CURRENCY,
                                  amount: formatNumDisplay(item.total)
                              })
                            }}
                          </span>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <div class="t11 pt5"
                           v-if="item.items.length > 0 && Object.keys(item.items[0].first).length > 0 && Object.keys(item.items[0].first.second).length > 0">
                        <span class="block-left text-black"><span class="menu-price-sub">{{(item.items[0].first.second.name)}}</span></span>
                        <div class="fw600 block-right  text-black  t14"
                             v-if="item.items[0].first.second.price && !item.items[0].topping.extra && !item.items[0].topping.extra.length">
                          <span>
                            {{
                              $t('currency_display', {
                                  currency: $const.CURRENCY,
                                  amount: formatNumDisplay(item.items[0].first.second.price)
                              })
                            }}
                          </span>
                        </div>
                        <div class="clearfix"></div>
                      </div>

                      <div class="topping-box" v-if="item.items.length && item.items[0].topping.extra.length">
                        <!-- <div class="heading">{{$t('cart.plasting')}}</div> -->
                        <div v-if="item.items.length > 0 && isSpecialTopping(extra)"
                             v-for="(extra, index) in item.items[0].topping.extra" class="content  "
                             :key="genUniqueKey(extra)">
                          <span class="block-left"><span class="menu-price-sub">{{$t('cart.plasting')}} {{(extra.name.split("-")[0])}}</span></span>
                          <div class="fw600 block-right  text-black  t14" v-if="extra.price > 0">
                            <span>
                              {{
                                $t('currency_display', {
                                    currency: $const.CURRENCY,
                                    amount: formatNumDisplay(extra.price)
                                })
                              }}
                            </span>
                          </div>
                          <div class="clearfix"></div>
                        </div>
                        <div v-for="(extra, index) in item.items[0].topping.extra" class="content  "
                             v-if="item.items.length > 0 && !isSpecialTopping(extra)"
                             :key="genUniqueKey(extra)">
                          <span class="block-left">
                            <span class="menu-price-sub" v-if="extra.quantity > 1">
                              {{$t('format_product',{
                                product_name: (extra.name.split('-')[0]),
                                quantity: extra.quantity
                              })}}
                            </span>
                            <span class="menu-price-sub" v-else>{{(extra.name.split('-')[0])}}</span>
                          </span>
                          <div class="fw600 block-right  text-black  t14" v-if="extra.price > 0">
                            <span>
                              {{
                                $t('currency_display', {
                                    currency: $const.CURRENCY,
                                    amount: formatNumDisplay(extra.price)
                                })
                              }}
                            </span>
                          </div>
                          <div class="clearfix"></div>
                        </div>
                      </div>

                      <div class="topping-box" v-if="item.items.length && item.items[0].topping.remove.length">
                        <!-- <div class="heading">{{$t('cart.minus')}}</div> -->
                        <div v-if="item.items.length > 0" v-for="(remove, index) in item.items[0].topping.remove"
                             class="content  " :key="genUniqueKey(remove)">
                          <span class="block-left"><span class="menu-price-sub">{{$t('cart.minus')}} {{(remove.name.split('-')[0])}}</span></span>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                    </div>
                    <!--COMBO-->
                    <div v-else-if="item.type ===1"
                         class="flex1 half">
                      <div class="pt10 fw600">
                        <span class="block-left text-black t14">
                          <span>
                            <span v-if="item.quantity > 1">{{$t('format_product',{
                                product_name: (item.name),
                                quantity: item.quantity
                              })}}
                            </span>
                            <span v-else>{{(item.name)}}</span>
                          </span>
                        </span>
                        <div class="fw600 block-right  text-black  t14">
                          <span>
                            {{
                              $t('currency_display', {
                                  currency: $const.CURRENCY,
                                  amount: formatNumDisplay(item.total)
                              })
                            }}
                          </span>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <div v-for="(menu, i) in item.items" :key="genUniqueKey(menu)">
                        <div class="pt10">
                          <div class="block-left text-black t14">
                            <span class="heading-sm">
                              <b>{{ menu.name }}</b>
                            </span>
                          </div>
                          <div class="clearfix"></div>
                        </div>

                        <div v-if="menu.first.name"
                          class="layer-name">
                          <span>{{ menu.first.name }}</span> - {{ menu.first.second.name }}
                        </div>

                        <div class="topping-box" v-if="menu.topping.extra.length">
                          <!--<div class="heading">{{$t('cart.plasting')}}</div>-->
                          <div v-for="(extra, index) in menu.topping.extra" v-if="isSpecialTopping(extra)"
                               class="content  " :key="genUniqueKey(extra)">
                            <span class="block-left"><span class="menu-price-sub">{{$t('cart.plasting')}} {{(extra.name.split("-")[0])}}</span></span>
                            <div class="fw600 block-right  text-black  t14" v-if="extra.price > 0">
                              <span>
                                {{
                                  $t('currency_display', {
                                      currency: $const.CURRENCY,
                                      amount: formatNumDisplay(extra.price)
                                  })
                                }}
                              </span>
                            </div>
                            <div class="clearfix"></div>
                          </div>

                          <div v-for="(extra, index) in menu.topping.extra" v-if="!isSpecialTopping(extra)"
                               class="content  " :key="genUniqueKey(extra)">
                            <span class="block-left">
                               <span class="menu-price-sub" v-if="extra.quantity > 1">
                                {{$t('format_product',{
                                  product_name: (extra.name.split("-")[0]),
                                  quantity: extra.quantity
                                })}}
                              </span>
                              <span class="menu-price-sub" v-else>{{(extra.name.split("-")[0])}}</span>

                            </span>
                            <div class="fw600 block-right  text-black  t14" v-if="extra.price > 0">
                              <span>
                                {{
                                  $t('currency_display', {
                                      currency: $const.CURRENCY,
                                      amount: formatNumDisplay(extra.price)
                                  })
                                }}
                              </span>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </div>

                        <div class="topping-box" v-if="menu.topping.remove.length">
                          <!--<div class="heading">{{$t('cart.minus')}}</div>-->
                          <div v-for="(remove, index) in menu.topping.remove" class="content  "
                               :key="genUniqueKey(remove)">
                            <span class="block-left"><span class="menu-price-sub">{{$t('cart.minus')}} {{(remove.name)}}</span></span>
                            <div class="clearfix"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--HALF HALF-->
                    <div class="flex1 half" v-else>
                      <div class="pt10 fw600">
                        <span class="block-left text-black t14">
                          <span>
                            <span v-if="item.quantity > 1">{{$t('format_product',{
                                product_name: (item.name),
                                quantity: item.quantity
                              })}}
                            </span>
                            <span v-else>{{(item.name)}}</span>
                          </span>
                        </span>
                        <div class="fw600 block-right  text-black  t14">
                          <span>
                            {{
                              $t('currency_display', {
                                  currency: $const.CURRENCY,
                                  amount: formatNumDisplay(item.total)
                              })
                            }}
                          </span>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <div v-for="(menu, i) in item.items" :key="genUniqueKey(menu)">
                        <div class="pt10">
                          <div class="block-left text-black t14">
                            <span class="heading-sm">
                              <b>{{ i ===0 ? $t('left_half') : $t('right_half') }}</b>
                            </span>
                          </div>
                          <div class="clearfix"></div>
                        </div>

                        <div class="layer-name">
                          <span>({{ menu.first.name }})</span> {{ menu.name }}
                        </div>
                        <!--<div class="t11 ml-20"
                             v-if="Object.keys(menu.first).length > 0 && Object.keys(menu.first.second).length > 0">
                          <span class="block-left text-black menu-price-sub">
                            <span class="">{{(menu.first.second.name)}}</span>
                          </span>
                          <div class="fw600 block-right  text-black  t14 " v-if="menu.first.second.price > 0">
                            <span>
                              {{
                                $t('currency_display', {
                                    currency: $const.CURRENCY,
                                    amount: formatNumDisplay(menu.first.second.price)
                                })
                              }}
                            </span>
                          </div>
                          <div class="clearfix"></div>
                        </div>-->

                        <div class="topping-box" v-if="menu.topping.extra.length">
                          <!--<div class="heading">{{$t('cart.plasting')}}</div>-->
                          <div v-for="(extra, index) in menu.topping.extra" v-if="isSpecialTopping(extra)"
                               class="content  " :key="genUniqueKey(extra)">
                            <span class="block-left"><span class="menu-price-sub">{{$t('cart.plasting')}} {{(extra.name.split("-")[0])}}</span></span>
                            <div class="fw600 block-right  text-black  t14" v-if="extra.price > 0">
                              <span>
                                {{
                                  $t('currency_display', {
                                      currency: $const.CURRENCY,
                                      amount: formatNumDisplay(extra.price)
                                  })
                                }}
                              </span>
                            </div>
                            <div class="clearfix"></div>
                          </div>

                          <div v-for="(extra, index) in menu.topping.extra" v-if="!isSpecialTopping(extra)"
                               class="content  " :key="genUniqueKey(extra)">
                            <span class="block-left">
                               <span class="menu-price-sub" v-if="extra.quantity > 1">
                                {{$t('format_product',{
                                  product_name: (extra.name.split("-")[0]),
                                  quantity: extra.quantity
                                })}}
                              </span>
                              <span class="menu-price-sub" v-else>{{(extra.name.split("-")[0])}}</span>

                            </span>
                            <div class="fw600 block-right  text-black  t14" v-if="extra.price > 0">
                              <span>
                                {{
                                  $t('currency_display', {
                                      currency: $const.CURRENCY,
                                      amount: formatNumDisplay(extra.price)
                                  })
                                }}
                              </span>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </div>

                        <div class="topping-box" v-if="menu.topping.remove.length">
                          <!--<div class="heading">{{$t('cart.minus')}}</div>-->
                          <div v-for="(remove, index) in menu.topping.remove" class="content"
                               :key="genUniqueKey(remove)">
                            <span class="block-left"><span class="menu-price-sub">{{$t('cart.minus')}} {{(remove.name)}}</span></span>
                            <div class="clearfix"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="total-box">
                    <div class="pl-10 pt-10 pr-10 t14 fw600" v-if="cart.order_coupons ">
                      <div class="display-flex" v-for="coupon in cart.order_coupons">
                        <div class="flex1">{{$t('cart.discount')+ ": "}}<span
                          v-if="coupon.type != $const.COUPON_TYPE_ITEM && coupon.discount_quantity && coupon.discount_quantity > 0">{{coupon.discount_quantity}} x </span>{{coupon.coupon_code
                          }}
                        </div>
                        <p v-if="coupon.type ===$const.COUPON_TYPE_FREE_DELIVERY"><span><span class="t15">{{$t('cart.free_delivery')}}</span></span>
                        </p>
                        <p v-else>
                        <span class="t16">
                          {{
                                $t('currency_display', {
                                    currency: $const.CURRENCY,
                                    amount: formatNumDisplay(coupon.discount)
                                })
                              }}
                        </span>
                        </p>
                      </div>
                    </div>

                    <div class="pl10 pr5 t14 bold">
                      <div class="display-flex">
                        <div>{{$t('cart.delivery_fee')}}</div>
                        <span class="t18">
                        <span id="delivery_fee" class="t14">
                        {{
                          $t('currency_display', {
                              currency: $const.CURRENCY,
                              amount: formatNumDisplay(cart.delivery_fee)
                          })
                        }}
                        </span>
                      </span>
                      </div>
                    </div>

                    <div class="pl10 pr5 t16 bold">
                      <div class="display-flex">
                        <div>{{$t('cart.total')}}</div>
                        <span class="t18">
                        <span id="total_price" class="t18" data-synth="total-price">
                        {{
                          $t('currency_display', {
                              currency: $const.CURRENCY,
                              amount: formatNumDisplay(cart.total)
                          })
                        }}
                        </span>
                      </span>
                      </div>
                    </div>

                    <div class="pl-10 pt-10 pr-10  bold" v-if="cart.tax_percent > 0">
                      <div class="display-flex">
                        <div>{{$t('cart.service_tax')}}</div>
                        <span class="t14">
                        {{
                          $t('currency_display', {
                              currency: $const.CURRENCY,
                              amount: (cart.tax_total) ? formatNumDisplay(cart.tax_total) : 0
                          })
                        }}
                      </span>
                      </div>
                    </div>
                  </div>
                  <!--<div class="pl-10 pt-10 pr-10  bold">
                    <div class="display-flex">
                      <div class="flex1">{{$t('cart.subtotal')}}</div>
                      <span class="t14">{{$const.CURRENCY}} <span class="t14"> {{formatNumDisplay(cart.sub_total)}}</span></span>
                    </div>
                  </div>-->
                </div>
              </div>
            </transition>
          </header>
        </div>
      </div>
      <div class="md:flex-1 right">
        <section class=" md:py-0 text-left mx-20">
          <div>
            <h2 class="store_name t15 pb-10 text-grey-darkest fw400">
              <span v-if="cart.order_type && cart.order_type.toLowerCase() ==='d'"
                    v-html="$t('delivery_address', {store_name: cart.outlet_name, storeCode: cart.outlet_code})">x
              </span>
              <span v-else
                    v-html="$t('carryout_from', {store_name: cart.outlet_name})">
              </span>
            </h2>

            <p v-if="cart.order_type && cart.order_type.toLowerCase() ==='c'"
               class="t15 capitalize">
              <a v-on:click.stop.prevent="openMap(cart.outlet_code)" style="cursor: pointer">
                <b data-synth="coldel-address">{{ cart.outlet_address }}</b>
              </a>
            </p>
            <p v-else
               class="t15 capitalize">
              <b data-synth="coldel-address">{{ cart.address }}</b>
            </p>
          </div>
          <div class="">
            <h2 class="t15 bold text-grey-darkest capitalize"><span>{{$t('pament_method')}}</span></h2>
            <div class="flex">
              <div class="t15 bold uppercase">
                <span>{{paym}}</span>
              </div>
            </div>
          </div>
          <section class=" display-flex pt10 text-row">
            <span class="icon-email mr-10 xs-h-20" alt="email icon"></span>
            <div class="text-left t13">
              <span v-html="$t('confirm_sendmail_format', {address: cart.email})"></span>
            </div>
          </section>
          <div class="section-description"
               v-html="$t('tracker_note', { store: cart.outlet_name, storeCode: cart.outlet_code })"></div>
        </section>
      </div>
    </div>
    <div class="select-qa bg-grey-lightestest  display-flex p10 " style="">
      <div class="display-flex">
        <span class="icon-question flex-no-shrink mr-10 xs-h-20 ml-10" alt="question mark icon"></span>
        <span class=" bold text-grey-darkest t16">{{$t('question_order')}}</span>
      </div>
      <div class="display-flex">
        <div class="t16 pl20 font15-sm"><span
          class="font15-sm">{{$t('call_customer_service')}} </span><span>&nbsp;</span><a href="tel:$const.TEL">{{$const.TEL}}</a>
        </div>
      </div>
    </div>
    <div>
      <div class="border-t text-center">
        <div class="py-10 px-30 display-flex justify-between flex-wrap justify-around ">
          <router-link class="no-underline px-10 btn btn-link-footer" to="/">{{$t('home')}}</router-link>
          <a href="javascript: void(0);" class="no-underline px-10 btn btn-link-footer"
             @click="$root.$emit('onProcessChangeCategory')">{{$t('all_deal')}}</a>
          <router-link class="no-underline px-10 btn btn-link-footer" to="/faq">{{$t('footer.faqs')}}</router-link>
          <router-link class="no-underline px-10 btn btn-link-footer" to="/terms">{{$t('terms')}}</router-link>
          <router-link class="no-underline px-10 btn btn-link-footer" to="/privacy">{{$t('footer.cft_sec')}}
          </router-link>
        </div>
        <p class="text-grey text-center py-10">
          <span>{{ $t('copyright', { year: (new Date().getFullYear()) }) }}</span>
        </p>
      </div>
    </div>
    <!--SECURE IMAGE PIXEL CODE START-->
    <img src="https://acuityplatform.com/Adserver/pxl/512295559846428116?" style="float:left" width="1" height="1"/>
    <!--SECURE IMAGE PIXEL CODE END-->
  </div>
</template>
<script>
import main from '../main'
import Ellipsis from '@/components/partials/AnimatedEllipsis'
import moment from 'moment'
/* import ModalGoogleMap from '../components/popup/ModalGoogleMap' */

export default {
  components: {
    Ellipsis
  },
  data () {
    return {
      // rakutenImg: '',
      show: true,
      user: {},
      paym: '',
      cart: {},
      odrSingle: [],
      odrCombo: {},
      odrCbTotal: [],
      itervalObj: null,
      command: '01-accepted',
      commands: ['01-accepted', '02-preparing', '03-completed', '04-done'],
      oStatus: this.$t('tracker_received'),
      oListStatus: [
        this.$t('tracker_received'),
        this.$t('tracker_cooking'),
        this.$t('tracker_delivering'),
        this.$t('tracker_complete'),
        this.$t('tracker_cancel'),
        this.$t('tracker_ready')],
      orderStatus: null,
      isDelivery: false,
      errorMessage: ''
    }
  },
  created () {
    let vm = this
    let uuid = this.$route.params.uuid

    if (uuid) {
      this.getData(uuid)
    }

    this.itervalObj = setInterval(function () {
      vm.checkStatus(vm.$route.params.uuid)
    }, 5000)
  },
  mounted: function () {
        let pageInfo = {
      screen: {
        name: 'checkout.confirmation',
        type: 'checkout'
      }
    }
    this.eventTrackingScreenLoad(this, pageInfo)
  },
  destroyed () {
    clearInterval(this.itervalObj)
    return false
  },
  methods: {
    /* formatName(data) {
      var text = '';
      if (data.items.length) {
        if (data.items[0].layer_info && data.items[0].layer_info.first) {
          var size = data.items[0].layer_info.first.name.toLowerCase();
          text = size;
        }

        text += " " + data.name;
      }

      return text;
    }, */
    isSpecialTopping (topping) {
      return topping.special == 1 || topping.special == 2; //Using soft equals to capture '1' && '2'
    },
    formatName (data, isHalf = false) {
      let text = ''
      if (isHalf) {
        text += '(' + data.layer_info.first.name + ') ' + data.name
      } else {
        if (data.items.length) {
          if (data.items[0].layer_info && data.items[0].layer_info.first) {
            text = data.items[0].layer_info.first.name
          }

          text += ' ' + data.name
        }
      }

      return text
    },
    // generateRakutenImg (data) {
    //   // TODO temporary hardcode for dev env
    //   let protocol = window.location.protocol.replace(/:/g, '') + '://'
    //   let url = protocol + 'track.linksynergy.com/eventnvppixel' + '?mid=' + '37307' + '&ord=' + data.ord + '&tr=' + data.tr + '&land=' +
    //     data.land + '&skulist=' + data.skulist + '&qlist=' + data.qlist + '&amtlist=' + data.amtlist + '&cur=' + data.cur +
    //     '&namelist=' + data.namelist
    //   this.rakutenImg = url
    // },
    getCookie (name) {
      var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
      return v ? v[2] : null
    },
    getPaymName (info) {
      this.paym = info
    },
    checkStatus (uuid) {
      var vm = this
      this.axios.apiOrder.getOrder(uuid, function (response) {
        if (response.data.data.item) {
          if (response.status === 200) {
            let dataObject = response.data.data.item
            vm.setStatus(dataObject.status)
            if ([4, 7, 8].includes(dataObject.status)) {
              clearInterval(vm.itervalObj)
            }
          }
        }
      }, function () {
        clearInterval(vm.itervalObj)
      })
    },
    updateTrackingStatus (uuid) {
      var vm = this
      this.axios.apiOrder.updateTrackingStatus(uuid, {is_tracking: 1}, function (response) {
      }, function () {
      })
    },
    getData (uuid) {
      main.callEventBus(main.$const.EVENT_BUS.LOADING, true)

      var vm = this
      this.axios.apiOrder.getOrder(uuid, function (response) {
        if (response.data.data.item) {
          if (response.status === 200) {
            vm.cart = response.data.data.item
            vm.cart.timezone = vm.cart.outlet_timezone
            vm.getPaymName(vm.cart.payment_method)
            // gerate rakuten image
            // var rakutenSID = vm.getCookie('RAKUTEN_SID')
            // if (rakutenSID != null) {
            //   let orderItems = vm.cart.order_items
            //   let skulist = []
            //   let qlist = []
            //   let amtlist = []
            //   let cur = vm.cart.currency
            //   let namelist = []
            //   for (let i = 0; i < orderItems.length; i++) {
            //     let items = orderItems[i].items
            //     for (let j = 0; j < items.length; j++) {
            //       skulist.push(items[j].sku)
            //       qlist.push(items[j].quantity)
            //       amtlist.push(items[j].total)
            //       namelist.push(items[j].name)
            //     }
            //   }
              // let rakutenDate = moment(vm.getCookie('RAKUTEN_DATE'), 'YYYY-MM-DD HH:mm:ss')
              // let rakutenData = {
              //   ord: vm.cart.order_number,
              //   tr: rakutenSID, // siteID in cookie
              //   land: rakutenDate.format('YYYYMMDD_HHmm'), // date in cookie
              //   skulist: 'ORDER',
              //   qlist: 1,
              //   amtlist: vm.cart.total - vm.cart.tax_total,
              //   cur: cur,
              //   namelist: 'ORDER'
              // }
              // vm.generateRakutenImg(rakutenData)
            // }

            vm.setStatus(vm.cart.status)

            let today = moment.utc(new Date()).tz(vm.cart.timezone).format('YYYY-MM-DD')
            let nextday = moment.utc(new Date()).tz(vm.cart.timezone).add(1, 'days').format('YYYY-MM-DD')
            let orderTime = vm.convertUTCToTimeZone(vm.cart.collection_time, vm.cart.timezone)

            let pickedDay = orderTime.format('YYYY-MM-DD')
            let pickedHours = orderTime.format('HH:mm')

            if (vm.cart.order_type.toLowerCase() === 'd') {
              vm.isDelivery = true

              if (vm.cart.advance_order === 0) {
                pickedHours = orderTime.format('HH:mm')
              } else {
                let duration = vm.cart.interval || 0
                orderTime.subtract(duration, 'minutes')

                pickedHours = orderTime.format('HH:mm') + '~' + pickedHours
              }
            }

            if (!vm.cart.collection_time) {
              vm.cart.collection_time_str = vm.$t('asap')
            } else if (pickedDay === today) {
              vm.cart.collection_time_str = vm.$t('today') + ' ' + pickedHours
            } else if (pickedDay === nextday) {
              vm.cart.collection_time_str = vm.$t('tomorrow') + ' ' + pickedHours
            } else {
              vm.cart.collection_time_str = moment(vm.cart.collection_time).format('YYYY-MM-DD') + ' ' + pickedHours
            }

            vm.$root.$emit('excuseCommand', vm.command)

            if (vm.$i18n.locale === vm.$const.LANG_ES) {
              // var name = $auth.user().first_name  + $auth.user().last_name
              if (vm.orderStatus === vm.$const.ORDER_STATUS.ERROR) {
                vm.$root.$emit('onChangeMetaTitle', vm.$t('header.thanks_for_your_order', {name: vm.cart.name}))
              } else {
                vm.$root.$emit('onChangeMetaTitle', vm.$t('header.thanks_for_your_order', {name: vm.cart.name}))
              }
            } else {
              vm.$root.$emit('onChangeMetaTitle', vm.$t('header.thanks_for_your_order', {name: vm.cart.name}))
            }
            if (!vm.cart.is_tracking) {
              vm.updateTrackingStatus(uuid)
              vm.eventTrackingConfirmation(vm, vm.cart) // Put it here since cart seems to have required property that checkout page does not
            }
          }
        }

        main.callEventBus(main.$const.EVENT_BUS.LOADING, false)
      }, function () {
        main.callEventBus(main.$const.EVENT_BUS.LOADING, false)
        clearInterval(vm.itervalObj)
      })
    },
    setStatus (status) {
      let vm = this
      if (vm.lodash.includes(this.$const.ORDER_STATUS.STARTING, status)) {
        this.oStatus = this.$t('tracker_received')
        this.orderStatus = this.$const.ORDER_STATUS.STARTING
      } else if (vm.lodash.includes(this.$const.ORDER_STATUS.RECEIVED, status)) {
        this.oStatus = this.$t('tracker_received')
        this.orderStatus = this.$const.ORDER_STATUS.STARTING
        // this.oStatus = this.$t('tracker_accepted')
        // this.orderStatus = this.$const.ORDER_STATUS.RECEIVED;
      } else if (vm.lodash.includes(this.$const.ORDER_STATUS.COOKING, status)) {
        this.oStatus = this.$t('tracker_received')
        this.orderStatus = this.$const.ORDER_STATUS.STARTING
        // this.oStatus = this.$t('tracker_cooking')
        // this.orderStatus = this.$const.ORDER_STATUS.COOKING;
      } else if (vm.lodash.includes(this.$const.ORDER_STATUS.DELIVERING, status)) {
        this.oStatus = this.$t('tracker_received')
        this.orderStatus = this.$const.ORDER_STATUS.STARTING
        // this.oStatus = this.$t('tracker_delivering')
        // this.orderStatus = this.$const.ORDER_STATUS.DELIVERING;
      } else if (vm.lodash.includes(this.$const.ORDER_STATUS.READY, status)) {
        this.oStatus = this.$t('tracker_complete')
        this.orderStatus = this.$const.ORDER_STATUS.COMPLETED
        // this.oStatus = this.$t('tracker_ready')
        // this.orderStatus = this.$const.ORDER_STATUS.READY;
      } else if (vm.lodash.includes(this.$const.ORDER_STATUS.COMPLETED, status)) {
        this.oStatus = this.$t('tracker_complete')
        this.orderStatus = this.$const.ORDER_STATUS.COMPLETED
      } else if (vm.lodash.includes(this.$const.ORDER_STATUS.CANCELED, status)) {
        this.oStatus = this.$t('tracker_cancel')
        this.orderStatus = this.$const.ORDER_STATUS.CANCELED
      } else {
        this.oStatus = this.$t('tracker_error')
        this.errorMessage = this.$t('tracker_error_message')
        this.orderStatus = this.$const.ORDER_STATUS.ERROR
      }
    },
    openMap (outletCode) {
      this.axios.apiOutlet.getOutLetByCode({code: outletCode}, (response) => {
        let vm = this
        let data = response.data.data.item

        if (data && data.lat && data.long) {
          let link = `http://www.google.com/maps/place/${data.lat},${data.long}/@${data.lat},${data.long},17z`
          this.redirectToUrl(link, true, true)
        }
      })
    }
  }
}

</script>

<style>
  .msg-enter-active,
  .msg-leave-active {
    transition: all 0.5s;
  }

  .msg-enter,
  .msg-leave-to {
    opacity: 0;
  }

  .msg-enter {
    transform: translateX(31px);
  }

  .msg-leave-active {
    transform: translateX(-31px);
  }

  .btn-link-footer {
    background-color: #f4f6f7;
    border-color: #f4f6f7;
    border-radius: 0.5;
  }
</style>
