<template>
  <span><span class="ellipsis-one">.</span><span class="ellipsis-two">.</span><span class="ellipsis-three">.</span></span>
</template>

<script>
export default {
  name: 'AnimatedEllipsis',
  props: {
  },
  methods: {
  }
}
</script>

<style scoped>
.ellipsis-one {
    opacity: 0;
    -webkit-animation: ellipsis-dot 1.3s infinite;
    -webkit-animation-delay: 0.0s;
    animation: ellipsis-dot 1.3s infinite;
    animation-delay: 0.0s;
}

.ellipsis-two {
    opacity: 0;
    -webkit-animation: ellipsis-dot 1.3s infinite;
    -webkit-animation-delay: 0.2s;
      animation: ellipsis-dot 1.3s infinite;
      animation-delay: 0.2s;
}

.ellipsis-three {
    opacity: 0;
    -webkit-animation: ellipsis-dot 1.3s infinite;
    -webkit-animation-delay: 0.3s;
     animation: ellipsis-dot 1.3s infinite;
     animation-delay: 0.3s;
}

@-webkit-keyframes ellipsis-dot {
      0% { opacity: 0; }
     50% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes ellipsis-dot {
      0% { opacity: 0; }
     50% { opacity: 0; }
    100% { opacity: 1; }
}
</style>
